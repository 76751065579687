.main-wrapper {
  min-height: 100vh;
}

.content-wrapper {
  margin-left: 24rem;
  padding: 8.2rem 1.7rem 1.7rem 1.7rem;
  min-height: 100vh;
  @include transition(all 0.3s ease-in-out);

  @include media-breakpoint-down(lg) {
    margin-left: 0;
    padding: 8.2rem 1.2rem 1.2rem 1.2rem;
  }

  .conent-header {
    margin-bottom: 1.6rem;
    @extend .d-flex, .justify-content-between, .align-items-center;

    @media (max-width: 380px) {
      flex-wrap: wrap;
    }

    >.row {
      @extend .flex-fill;
    }

    .breadcrumb {
      @extend .mb-0;
    }
  }
}


// Temp css
.tooltip {
  position: fixed;
}