.toggle-switch {
  position: relative;
  width: 3.6rem;
  height: 2rem;
  display: block;

  input {
    display: none;

    &:checked {
      &+.switch-label {
        background-color: $primary;

        .switch-circle {
          left: calc(50% - 1.8rem/2 + .8rem);
        }
      }
    }
  }

  .switch-label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: rgba($black, .38);
    border-radius: 1.6rem;
    cursor: pointer;
    @include transition(all 0.2s ease-out 0s);

    .switch-circle {
      width: 1.8rem;
      height: 1.8rem;
      position: absolute;
      left: calc(50% - 1.8rem/2 - .8rem);
      border-radius: 50%;
      background-color: $white;
      box-shadow: 0px 3px 8px rgba($black, 0.15), 0px 3px 1px rgba($black, 0.06);
      cursor: pointer;
      @include transition(all 0.2s ease-out 0s);
    }
  }
}