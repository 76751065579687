@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

html,
body {
    min-height: 100vh;
    overflow-x: hidden;
}

body {
    background-color: $light-gray;
}


:root {
    @include media-breakpoint-down(xxl) {
        font-size: 58.5%;
    }
    @include media-breakpoint-down(lg) {
        font-size: 55%;
    }
}


::selection {
    background-color: rgba($primary, .2);
    color: $body-color;
}

p {
    line-height: 1.5;
}

h1 {
    font-size: 3.6rem;
}

h2 {
    font-size: 3.2rem;
}

h3 {
    font-size: 2.8rem;
}

h4 {
    font-size: 2.4rem;
}

h5 {
    font-size: 2rem;
    line-height: 1.2;
}

h6 {
    font-size: 1.6rem;
}

.fs-15 {
    font-size: 1.5rem !important;
}

.fs-16 {
    font-size: 1.6rem !important;
}

.fw-medium {
    font-weight: 500 !important;
}

.title-h1 {
    font-size: 2.4rem;
    font-weight: 600;
    margin-bottom: 0;

    @include media-breakpoint-down(sm) {
        font-size: 2rem;
    }

    @media (max-width: 380px) {
        width: 100%;
    }
}