.sidebar-wrapper {
  position: fixed;
  width: 24rem;
  top: 0;
  bottom: 0;
  z-index: 102;
  background: linear-gradient(to bottom, #fba79d, #f99b90, #f78e83, #f48176, #f17469);
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;

  @include media-breakpoint-up(lg) {
    white-space: nowrap;
  }

  @include media-breakpoint-down(lg) {
    transform: translateX(-24.1rem);
  }

  .brand-link {
    @extend .text-center, .p-2, .mb-1;

    img {
      max-height: 4.8rem;
    }
  }

  .sidebar-menu {
    padding: 0;
    height: calc(100% - 6.4rem);
    list-style: none;
    margin-bottom: 0;
    overflow-y: auto;

    >li {
      margin-bottom: 0.4rem;

      >.nav-item {
        position: relative;
        font-size: 1.6rem;
        padding: 1rem 1.6rem;
        color: $white;
        cursor: pointer;
        @extend .d-flex, .align-items-center;

        >i:not(:last-child) {
          width: 2rem;
          font-size: 1.6rem;
          @extend .flex-shrink-0;
        }

        >i:last-child {
          transition: transform 0.3s ease-in-out;
        }

        >span {
          width: auto;
          padding-left: .6rem;
          opacity: 1;
          transition: opacity 0.3s ease-in-out, width 0.3s ease-in-out;
          @extend .flex-fill;
        }

        &:hover {
          text-decoration: none;
        }

        &.active {
          background-color: rgba($white, .3);
          color: $white;
        }

        &.is-open {
          >i:last-child {
            transform: rotate(-180deg);
            transition: transform 0.3s ease-in-out;
          }

          &+ul {
            height: auto;
          }
        }
      }

      ul {
        height: 0;
        overflow: hidden;
        background-color: rgba($black, .1);
        transition: height 0.3s ease-in-out;
        @extend .list-unstyled;

        &.active {
          height: auto;
        }

        li {
          margin-bottom: .4rem;

          >.nav-item {
            position: relative;
            font-size: 1.4rem;
            padding: .6rem 1.6rem .6rem 3.2rem;
            color: $white;
            cursor: pointer;
            @extend .d-flex, .align-items-center;

            i {
              width: 1.4rem;
              font-size: 1.2rem;
              @extend .flex-shrink-0;
            }

            >span {
              padding-left: .6rem;
              @extend .flex-fill;
            }

            &:hover {
              text-decoration: none;
            }

            &.active {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

.sidebar-toggle {
  .main-header {
    margin-left: 0;
  }

  .sidebar-wrapper {
    transform: translateX(-24.1rem);

    @include media-breakpoint-down(lg) {
      transform: translateX(0);
    }
  }

  .content-wrapper {
    @include media-breakpoint-up(lg) {
      margin-left: 0;
    }
  }

  .sidebar-overlay {
    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
}

.sidebar-overlay {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0, .4);
  z-index: 101;
  display: none;
}