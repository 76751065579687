.auth-page-wrapper {
  min-height: 100vh;
  padding: 2.4rem 0;
  @extend .d-flex, .justify-content-center, .align-items-center;
}

.auth-box {
  max-width: 46rem;
  width: 100%;
  margin: 0 auto;

  .header-logo {
    max-height: 10rem;
  }

  .auth-card {
    background-color: $white;
    box-shadow: 0 0 2px rgba($black , .2);

    .auth-card-body {
      padding: 3.2rem;

      @media (max-width: 380px) {
        padding: 2.4rem;
      }

      h1 {
        font-size: 2.4rem;
        text-transform: uppercase;
      }
    }
  }
}