@font-face {
  font-family: "icomoon";
  // src: url("../fonts/icomoon.eot");
  // src: url("../fonts/icomoon.eot") format("embedded-opentype"),
  //   url("../fonts/icomoon.ttf") format("truetype"),
  //   url("../fonts/icomoon.woff") format("woff"),
  //   url("../fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icons: (
  eye-slash: "\e900",
);

@each $name,
$icon in $icons {
  .icon-#{$name}:before {
    content: $icon;
  }
}