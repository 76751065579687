.table {
  margin-bottom: 0;

  thead {
    th {
      white-space: nowrap;
    }
  }

  tbody {
    tr {
      &:nth-of-type(odd) {
        background-color: #f2f3f7;
      }
    }
  }

  th,
  td {
    font-size: 1.5rem;
    vertical-align: middle;

  }

  // .sorting {
  //   position: relative;
  //   cursor: pointer;
  //   padding: 1rem 1.8rem 1rem 0.75rem;

  //   &:before,
  //   &:after {
  //     font-family: 'icomoon';
  //     position: absolute;
  //     right: 0.5rem;
  //     opacity: 0.2;
  //     font-size: 0.6rem;
  //     top: 50%;
  //     @include transform(translateY(-50%));
  //   }

  //   &:before {
  //     content: '\e91a';
  //     margin-top: -0.4rem;
  //   }

  //   &:after {
  //     content: '\e902';
  //     margin-top: 0.4rem;
  //   }

  //   &.sort-asc {
  //     &:before {
  //       opacity: 1;
  //     }
  //   }

  //   &.sort-desc {
  //     &:after {
  //       opacity: 1;
  //     }
  //   }
  // }
}


.action-btn-group {
  padding-left: 0;
  list-style: none;
  @extend .d-flex, .align-items-center, .mb-0;

  li {

    .btn,
    a {
      font-size: 1.3rem;
      border: none;
    }

    .btn-icon {
      width: 3.2rem;
      height: 3.2rem;
      padding: .4rem;
      @extend .d-flex, .align-items-center, .justify-content-center;

      &.btn-view {
        @include btn-action($orange-100, $white);
      }

      &.btn-edit {
        @include btn-action($blue, $white);
      }

      &.btn-delete {
        @include btn-action($primary, $white);
      }
    }

    &+li {
      margin-left: 1rem;
    }
  }
}