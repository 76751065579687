select {
  &.form-control {
    background-image: url('../images/select-arrow.svg');
    background-position: right 12px center;
    background-repeat: no-repeat;
    background-size: 16px 12px;
    padding-right: 36px;
  }
}

// .react-datepicker-wrapper {
//   input {
//     position: relative;
//     // z-index: 1;
//     background-color: transparent;
//     cursor: pointer;
//   }
// }

.form-pass {
  position: relative;

  .form-control {
    padding-right: 3.6rem;
  }

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    border: 0;
    outline: 0;
    background-color: transparent;
    color: $primary;
    padding: 0;

    &:hover {
      color: $primary;
    }
  }
}

.has-search-icon {
  position: relative;
  z-index: 11;

  .form-control {
    padding-left: 3.5rem;
  }

  .has-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    font-size: 1.4rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1.2rem;
    pointer-events: none;
    color: $gray-700;
    z-index: 1;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
}

.upload-file-wrap {
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
  }
}

.img-thumb-box {
  position: relative;
  max-width: 180px;
  width: 100%;
  margin-bottom: 1rem;

  .upload-file-wrap {
    position: absolute;
    width: 3.4rem;
    height: 3.4rem;
    bottom: -1rem;
    right: -1rem;
    line-height: 3.4rem;
    text-align: center;
    border-radius: 50%;
    font-size: 2rem;
    background-color: $gray-700;
    color: $white;
    margin: 0;
    z-index: 1;
  }
}

.img-thumb {
  border-radius: .8rem;
  border: solid 1px $light-gray-100;
  @include img-video-box(100%);
}

.media-thumb {
  border-radius: .8rem;
  border: solid 1px $light-gray-100;
  @include img-video-box(80%);
}

.page-search {
  @extend .d-flex;

  .btn {
    margin-left: .8rem;
    width: 20rem;
    border-radius: 2.4rem;
  }

  .input-group {
    .form-select {
      max-width: 20rem;
      height: 4.8rem;
    }

    .form-control {
      height: 4.8rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px;
    }
  }
}

.btn-resend {
  color: $primary;
  background-color: transparent;
  @extend .p-0, .border-0, .fw-bold, .text-decoration-underline;

  &.disabled {
    opacity: .45;
    pointer-events: none;
  }
}

.error-text {
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: .4rem;
  color: $primary;
  @extend .mb-0;
}