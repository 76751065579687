@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

@mixin img-box($padding) {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: block;

  &::after {
    content: "";
    width: 100%;
    display: block;
    padding-bottom: $padding;
    background-color: $light-gray-200;
  }

  img,
  .avatar-name {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    min-height: 100%;
  }
}

@mixin video-box($padding) {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: block;

  &::after {
    content: "";
    width: 100%;
    display: block;
    padding-bottom: $padding;
  }

  video {
    position: absolute;
    left: 0;
    top: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@mixin img-video-box($padding) {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: block;

  &::after {
    content: "";
    width: 100%;
    display: block;
    padding-bottom: $padding;
  }

  video,
  img {
    position: absolute;
    left: 0;
    top: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

@mixin link($link, $hover, $active) {
  color: $link;

  &:hover {
    color: $hover;
  }

  &:active {
    color: $active;
  }
}

@mixin overlay($bg) {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  background: $bg;
}

@mixin btn-action($color, $bgcolor) {
  color: $color;
  background-color: rgba($color, .2);

  &:active {
    color: $bgcolor;
    background-color: $color;
  }
}