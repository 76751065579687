$font-family-sans-serif: "DM Sans", sans-serif;
$font-size-root: 62.5%;
$font-size-base: 1.6rem;
$line-height-base: 1.4;
$headings-line-height: 1.5;
$paragraph-margin-bottom: 1.6rem;
$enable-rfs: false;
$spacer: 1.6rem;
$grid-gutter-width: 1.6rem;
$white: #ffffff;
$black: #000000;
$red: #ee3158;
$gray: #061013;
$gray-200: #ced4da;
$gray-300: #a2a4a7;
$gray-400: #868689;


$light-gray: #f3f3f9;
$light-gray-100: #e2e2e2;
$light-gray-200: #f1f1f1;
$light-gray-300: #e9ecef;

$orange: #E25555;
$orange-100: #ff9f43;
$brown :#531e06;
$blue :#2d82fe;
$green :#28c76f;

$body-color: $gray;
$primary: $orange;

$nav-link-padding-y: 1.2rem;
$nav-link-padding-x: 1.6rem;
$navbar-nav-link-padding-x: 1.2rem;
$navbar-light-color: $gray;
$navbar-light-hover-color: $black;
$navbar-light-active-color: $black;

$input-btn-padding-y: .6rem;
$input-btn-padding-x: 1.2rem;
$input-line-height: 1.6;
$input-btn-font-size: 1.4rem;
$input-color: $gray;
$input-font-weight: 400;
$input-placeholder-color: $gray-400;
$input-bg: $white;
$input-border-color: $gray-200;
$input-border-radius: .6rem;
$input-box-shadow: none;
$input-focus-box-shadow: none;
$input-focus-border-color: $gray-300;
$form-label-color: $gray;
$form-label-font-size: 1.4rem;
$form-label-font-weight: 600;
$form-select-focus-border-color: $gray-200;
$form-select-border-radius: .6rem;
$form-select-focus-box-shadow: none;
$input-disabled-bg: $light-gray-300;

$btn-padding-y: 0.7rem;
$btn-padding-X: 1.2rem;
$btn-font-weight: 500;
$btn-border-radius: .6rem;
$btn-font-size: 1.6rem;
$btn-line-height: 1.5;
$btn-box-shadow: none;

$link-decoration: none;
$link-shade-percentage: 10%;

$modal-inner-padding: 1.6rem;
$modal-header-padding-y: 1.6rem;
$modal-header-border-color: $gray-200;
$modal-content-border-radius: .8rem;
$modal-content-inner-border-radius: .8rem;
$modal-footer-border-width: 0;
$modal-footer-margin-between: .8rem;

$table-cell-padding-y: .6rem;
$table-cell-padding-x: .8rem;
$table-border-color: $light-gray-100;
$table-th-font-weight: 600;
$table-bg: transparent;

$spinner-width-sm: 1.6rem;

$form-check-input-focus-box-shadow: none;

$datepicker__selected-color: $primary;
$datepicker__border-radius: 0.4rem;
$datepicker__day-margin: 0.3rem;
$datepicker__font-size: 1.3rem;
$datepicker__font-family: $font-family-sans-serif;
$datepicker__item-size: 2.7rem;
$datepicker__margin: 0.6rem;
$datepicker__navigation-button-size: 32px;
$datepicker__triangle-size: 8px;